#app.auth.login-signup {

  .columns.large-13.large-centered {
    width: 100% !important;

    .content-block > .content {
      font-size: 12px;

      p {
        font-weight: 600;
      }
    }
  }

  .form-col {
    display: flex;
    justify-content: space-between;
  }

  @media only screen and (max-width: 435px) {
    .form-col {
      flex-direction: column;
    }
  }
}

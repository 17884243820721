
    .row.loader{
        margin: auto;
        width: 7%;
    }

    /*!
     * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
     * Copyright 2015 Daniel Cardoso <@DanielCardoso>
     * Licensed under MIT
     */

    .la-ball-atom,
    .la-ball-atom > div {
        position: relative;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .la-ball-atom {
        display: block;
        font-size: 0;
        color: #fff;
    }

    .la-ball-atom.la-dark {
        color: #333;
    }

    .la-ball-atom > div {
        display: inline-block;
        float: none;
        background-color: currentColor;
        border: 0 solid currentColor;
    }

    .la-ball-atom {
        width: 32px;
        height: 32px;
    }

    .la-ball-atom > div:nth-child(1) {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 60%;
        height: 60%;
        background: #436295;
        border-radius: 100%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-animation: ball-atom-shrink 4.5s infinite linear;
        animation: ball-atom-shrink 4.5s infinite linear;
    }

    .la-ball-atom > div:nth-child(2) {
        color: #f1b927;
    }

    .la-ball-atom > div:nth-child(3) {
        color: #1a3a58;
    }

    .la-ball-atom > div:nth-child(4) {
        color: #d26430;
    }

    .la-ball-atom > div:not(:nth-child(1)) {
        position: absolute;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background: none;
        -webkit-animation: ball-atom-zindex 1.5s 0s infinite steps(2, end);
        animation: ball-atom-zindex 1.5s 0s infinite steps(2, end);
    }

    .la-ball-atom > div:not(:nth-child(1)):before {
        position: absolute;
        top: 0;
        left: 0;
        width: 10px;
        height: 10px;
        margin-top: -5px;
        margin-left: -5px;
        content: "";
        background: currentColor;
        border-radius: 50%;
        opacity: .75;
        -webkit-animation: ball-atom-position 1.5s 0s infinite ease, ball-atom-size 1.5s 0s infinite ease;
        animation: ball-atom-position 1.5s 0s infinite ease, ball-atom-size 1.5s 0s infinite ease;
    }

    .la-ball-atom > div:nth-child(2) {
        -webkit-animation-delay: .75s;
        animation-delay: .75s;
    }

    .la-ball-atom > div:nth-child(2):before {
        -webkit-animation-delay: 0s, -1.125s;
        animation-delay: 0s, -1.125s;
    }

    .la-ball-atom > div:nth-child(3) {
        -webkit-transform: rotate(120deg);
        transform: rotate(120deg);
        -webkit-animation-delay: -.25s;
        animation-delay: -.25s;
    }

    .la-ball-atom > div:nth-child(3):before {
        -webkit-animation-delay: -1s, -.75s;
        animation-delay: -1s, -.75s;
    }

    .la-ball-atom > div:nth-child(4) {
        -webkit-transform: rotate(240deg);
        transform: rotate(240deg);
        -webkit-animation-delay: .25s;
        animation-delay: .25s;
    }

    .la-ball-atom > div:nth-child(4):before {
        -webkit-animation-delay: -.5s, -.125s;
        animation-delay: -.5s, -.125s;
    }

    .la-ball-atom.la-sm {
        width: 16px;
        height: 16px;
    }

    .la-ball-atom.la-sm > div:not(:nth-child(1)):before {
        width: 4px;
        height: 4px;
        margin-top: -2px;
        margin-left: -2px;
    }

    .la-ball-atom.la-2x {
        width: 64px;
        height: 64px;
    }

    .la-ball-atom.la-2x > div:not(:nth-child(1)):before {
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
    }

    .la-ball-atom.la-3x {
        width: 96px;
        height: 96px;
    }

    .la-ball-atom.la-3x > div:not(:nth-child(1)):before {
        width: 30px;
        height: 30px;
        margin-top: -15px;
        margin-left: -15px;
    }

    /*
     * Animations
     */

    @-webkit-keyframes ball-atom-position {
        50% {
            top: 100%;
            left: 100%;
        }
    }

    @keyframes ball-atom-position {
        50% {
            top: 100%;
            left: 100%;
        }
    }

    @-webkit-keyframes ball-atom-size {
        50% {
            -webkit-transform: scale(.5, .5);
            transform: scale(.5, .5);
        }
    }

    @keyframes ball-atom-size {
        50% {
            -webkit-transform: scale(.5, .5);
            transform: scale(.5, .5);
        }
    }

    @-webkit-keyframes ball-atom-zindex {
        50% {
            z-index: 10;
        }
    }

    @keyframes ball-atom-zindex {
        50% {
            z-index: 10;
        }
    }

    @-webkit-keyframes ball-atom-shrink {
        50% {
            -webkit-transform: translate(-50%, -50%) scale(.8, .8);
            transform: translate(-50%, -50%) scale(.8, .8);
        }
    }

    @keyframes ball-atom-shrink {
        50% {
            -webkit-transform: translate(-50%, -50%) scale(.8, .8);
            transform: translate(-50%, -50%) scale(.8, .8);
        }
    }


[class^="icon-"]:before {
  @include familyid-icon;
}

//@include icons{
//  &.large{
//    font-size: rem(24px);
//  }
//}
.icon-calendar{
  &:before {
    content: "\e605";
  }
}
.icon-facebook-2{
  &:before {
    color: #2B5C9E;
    content: "\e610";
  }
}
.icon-twitter{
  &:before {
    color: #15B9EE;
    content: "\e60f";
  }
}
.icon-clock{
  &:before {
    content: "\e607";
  }
}
.icon-trash{
  &:before {
    content: "\e606";
  }
}
.icon-remove{
  &:before {
    content: "\e60a";
  }
}
.file-field {
  .icon-remove {
    position: relative;
    top: -17px;
  }
}
.icon-chart{
  &:before {
    content: "\e608";
  }
}
.icon-move{
  &:before {
    content: "\e609";
  }
}
.icon-completed{
  &:before {
    content: "\e604";
  }
}
.icon-add{
  &:before {
    content: "\e60c";
  }
}
.icon-arrow-left{
  &:before {
    content: "\e601";
  }
}
.icon-arrow-right{
  &:before {
    content: "\e602";
  }
}
.icon-plus{
  &:before {
    content: "\e60c";
  }
}
.icon-help{
  &:before {
    content: "\e60d";
  }
}
.icon-remove-tag{
  &:before {
    content: "\e60e";
  }
}
.icon-edit{
  &:before {
    content: "\e612";
  }
}
.icon-search {
  &:before {
    content: "\e611";
    font-size: rem(30px);
    position: relative;
    top: rem(-8px);
  }
}
.icon-checkmark {
  @include familyid-icon;
  &:before {
    content: "\e603";
  }
}
.icon-paperclip {
  @include familyid-icon;
  &:before {
    content: "\e600";
  }
}

.icon-from-top {
  bottom: auto;
  top: 0;
}

.social-icon {
  width: 28px;
}
.modal-mask {
  background-color: rgba(0, 0, 0, .5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity .3s ease;
  width: 100%;
  z-index: 9998;

  .modal-wrapper {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(210, 210, 210, 0.6);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    .modal-container {
      padding: 2rem;

      header,
      .content {
        padding: 1rem;
      }
    }
  }
}

.modal-mask > .modal-wrapper > .modal-container > header {

}

.modal-mask > .modal-wrapper > .modal-container > .content {
  font-size: 1.6rem;
  text-align: center;
  &.left_align{
    text-align: left;
  }

  small {
    font-size: 1.4rem;
  }

  .buttons {

  }
  .row{
    &.centered-row{
      text-align: center;
    }
  }
}

.modal-mask > .modal-wrapper > .modal-container > .close-reveal-modal {
  background: #bfbfbf;
  border-radius: 5rem;
  cursor: pointer;
  font-size: 2.4rem;
  height: 2.2rem;
  line-height: 2rem;
  position: absolute;
  right: 1.4rem;
  text-align: center;
  top: 1.4rem;
  width: 2.2rem;
}

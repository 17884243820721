#app.auth.login {

  @media only screen and (max-width: 768px) {
    section[role=main] {
      width: 90%;
    }
  }

  .row {
    input.error {
      border: 1px solid #b61e11;
      box-shadow: 0 0 2px rgba(182, 30, 17, 0.49);
    }
  }

  .auth-cta {
    & > a {
      color: $color-link-blue;
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
}

@import 'variables';

html {
  box-sizing: border-box;
  color: $color-tundora;
  font-family: $font-primary;
  font-size: 62.5%; // 1rem = 10px
  height: 100%;
  line-height: 1.6;
}

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: none;
}

*,
*::before,
*::after,
*::-moz-focus-inner {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  background-color: $color-white;
}

a {
  color: $color-tundora;
  text-decoration: none;
}
#app.auth.reset-pass {
  .row {
    span.error {
      background-color: #fae3df;
      color: $alert-color;
      display: block;
      font-size: 1.3rem;
      margin-top: .5rem;
      text-align: right;
      border-radius: 4px;
      border: none;
      font-weight: 500;
      padding: 1.8rem 1.2rem 1rem 1.2rem;
      line-height: 1.8;
      margin-bottom: 1.25rem;
    }
  }
}



  .value{
    font-size: 14px
  }
  .error{
    background-color: #fae3df;
    color: #b61e11;
    border-radius: 4px;
    border: none;
    font-size: 1.3em;
    font-weight: 500;
    text-align: left;
    padding: 28.8px 19.2px 16px 19.2px;
    padding: 1.8rem 1.2rem 1rem 1.2rem;
    line-height: 1.8;
    margin-bottom: 20px;
    margin-bottom: 1.25rem;

  }
  .select.error{
    line-height: normal !important;
    line-height: initial !important;
    height: 10px !important;
  }

// Color Variables
$color-white:            #fff;
$color-black:            #05101a;
$color-gainsboro:        #f5f7fa;
$color-body-grey:        #66737f;
$color-placeholder-grey: #66737f;
$color-light-grey:       #e7e7e7;
$color-mystic:           #e1e6eb;
$color-babyblue:         #9fb3d1;
$color-chathams-blue:    #193c75;
$color-loblolly:         #bcc4cc;
$color-pigeon-post:      #b5c4db;
$color-indigo:           #467ccf;
$color-mariner:          #2967cc;
$color-steelblue:        #476da7;
$color-darkblue:         #1b3d73;
$color-black-pearl:      #05101a;
$color-light-blue:       #87a8d9;
$color-lime-green:       #6eb92d;
$color-light-green:      #87ca48;
$color-firebrick:        #9c1a0d;
$color-red:              #b61e11;
$color-link-blue:        #2770c6; // instead of 2d
$color-green:            #3d8405;
$color-alert-red-bg:     #fae3df;   //text red
$color-alert-blue-bg:    #e5eaf2;   //text darkblu
$color-alert-yellow-bg:  #fdf7e9;
$color-alert-yellow-text: #d8a224;
$color-green-bg:         #eaf4e1;   //text green
$custom-form-check-color: #476da7;

$color-gallery:          #ededed;
$color-tundora:          #484848;
$color-pomegranate:      #ee3324;
$color-casablanca:       #f6c347;
$alert-color:            #b61e11;


$custom-form-border-size: 1px;
$custom-form-border-color: #d9d9d9;
$custom-form-checkbox-radius: 2px;
$custom-form-checkbox-hover-color: #f5f7fa;

// Shadows
$box-shadow: (
        'light'     : 0 3px 8px rgba(0, 0, 0, .1),
        'medium'    : 0 3px 5px rgba(0, 0, 0, .2),
        'smudge'    : 0 7px 8px rgba(0, 0, 0, .1),
        'mild-line' : 0 1px 4px rgba(127,127,127,0.25),
);

// Font Variables
$font-primary: 'Open Sans', sans-serif;
$font-titles:  'Source Sans Pro', sans-serif;

$font-size: (
        'small'   : 1.2rem,
        'regular' : 1.6rem,
        'medium'  : 2rem,
        'large'   : 2.4rem,
        'x-large' : 3.2rem,
        'xx-large': 4.8rem,
);

$font-weight: (
        'light'  : 300,
        'regular': 400,
        'medium' : 600,
        'bold'   : 700,
        'x-bold' : 900,
);

// Responsive Breakpoints
$breakpoints: (
        'small' : 320px,
        'medium': 768px,
        'large' : 1024px,
) !default;

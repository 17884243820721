@media only screen and (max-width: 932px) {
  section[role=main] {
    width: 90%;
  }
}

input[aria-invalid="true"] {
  border: 1px solid #b61e11 !important;
  box-shadow: 0 0 1px 1px rgba(182, 30, 17, 0.79) !important;
}
input.error {
  border: 1px solid #b61e11 !important;
  box-shadow: 0 0 2px rgba(182, 30, 17, 0.49) !important;
}

section[role=main] {
  border-radius: 4px;
  box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.15);
  margin: auto;
  max-width: 60rem;
  padding: 2rem 4rem;
  width: 40%;
}

.modal-title,
.title {
  color: #05101A;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 2.5rem;
  margin-top: 1rem;
  text-align: center;
}

.modal-title {
  margin-bottom: 0;
}

.forgot {
  margin-top: .5rem;
  text-align: right;
  a {
    cursor: pointer;
  }
}

.row {
  display: flex;
  flex-direction: column;
  margin: auto auto 2rem;

  input[type=email],
  input[type=text],
  input[type=password] {
    box-sizing : border-box;
    background-color: #f5f7fa;
    border: 1px solid #e1e6eb;
    border-radius: 4px;
    margin-top: .5rem;
    padding: 1rem;
    width: 100%;
    //Safari hack to not cut off the bottom of the placeholder
    line-height: 160%;

    &::placeholder {
      color: #828e99;
      font-size: 1.4rem;
    }

    &:focus {
      box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    }

    &:focus ~ &::placeholder {
      color: darken(#828e99, 20);
    }
  }
}

.auth-cta {
  text-align: center;
  & > a {
    color: $color-link-blue;
    font-size: 1.4rem;
    font-weight: 600;
  }
}

.social-media {
  text-align: center;

  img {
    max-width: 2.5rem;
    margin: 0 0.5rem 0 0.5rem;
  }
}

span.required{
  color: $alert-color;
  padding-right: 5px;
  position: relative;
}


label {
  font-size: 1.2rem;
  font-weight: 600;

  small {
    font-weight: 100;
  }
}

.centre {
  margin: auto;
}

a {
  color: $color-link-blue;
  font-weight: 600;
}

.buttons {
  display: flex;
}

.buttons > button {
  margin-left: 1rem;
}

@media only screen and (max-width: 453px) {
  .reverse-button-order {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .buttons {
    align-items: center;
    flex-direction: column;
  }

  .buttons > button {
    margin-left: 0;
  }

  .buttons > a,
  .buttons > button {
    margin: 1rem;
    width: 17rem;
  }
}


/* Checkbox container */
.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .label-text {
    width: 80%;
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    //right: -0.7rem;
    height: 1.4rem;
    width: 1.4rem;
    background-color: $color-white;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #f5f7fa;
  }
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkmark.override:after {
  display: none !important;
}
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  top: 0.1rem;
  right: 0.3rem;
  width: 0.5rem;
  height: 0.8rem;
  border: solid $color-steelblue;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Style the mobile phone number input */
.int-tel-input-wapper {
  .int-tel-select,
  .int-tel-input input{
    background-color: #f5f7fa;
  }
  .int-tel-select input {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .int-tel-input input{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.int-tel-input-wapper.error .int-tel-select input,
.int-tel-input-wapper.error .int-tel-input input{
  box-shadow: 0 0 1px 1px rgba(182, 30, 17, 0.79) !important;
}

.select{
  position: relative;
  border: 1px solid #e1e6eb;
  height: 35px;
  font-size: 0.75rem;
  line-height: 1.4rem;
  padding: 0 0.625rem;
  background: $color-gainsboro;
  border-radius: 4px;

  &.error, .error &{
    box-shadow: 0 0 1px 1px rgba(182, 30, 17, 0.79) !important;
  }

  &.disabled, &.readonly{
    background-color: lighten($color-placeholder-grey, 25%);
    color: $color-black;
    .caret {
      &:after{
        border-color: $color-body-grey transparent transparent transparent;
      }
    }
  }
  &.disabled, &.readonly {
    opacity: 0.3 !important;
  }

  .selected{
    display: block;
    padding-right: 0.75rem;
    .value{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;
      display: block;
      position: absolute;
      top: 1rem;
      left: 1rem;
    }
  }

  &:hover{
    .caret{

      &:before{
        border-color: transparent transparent darken(#CDCDCD, 10%) transparent;
      }
      &:after{
        border-color: darken(#CDCDCD, 10%) transparent transparent transparent;
      }
    }
  }

  .caret{
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    width: 0.5625rem;
    height: 0.8125rem;

    &:after{
      transition: border 0.3s ease;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 3.5px 0 3.5px;
      border-color: #bbb transparent transparent transparent;
      bottom: 2px;
      left: 0;
      position: absolute;
    }
  }
  select{
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.4rem;
    &:hover {
      background: $color-gainsboro;
    }
  }
}
.select.has-id-parent_locale_select{
  width: 20%;
  font-size: 12px;
}




// new checkbox
.radio-field {
  input[type=radio] {
    opacity: 0;
    position: absolute;
    + span.label-text {
      &.small{
        font-weight: normal;
      }
      position: relative;;
      padding-left: 24px;
      vertical-align: top;
      float: left;
      border: 1px solid transparent;
      margin-bottom: 8px;

      &:before {
        @include familyid-icon;
        color: $custom-form-check-color;
        font-size: 20px;
        position: absolute;
        margin: -1px 0 0 -1px;
        opacity: 0;
        content: "";
        border: 3px solid $custom-form-check-color;
        border-radius: 50%;
        left: 6px;
        top: 6px;

      }
      &:after {
        content: "";
        position: absolute;
        border: solid $custom-form-border-size $custom-form-border-color;
        border-radius: 50%;
        height: 14px;
        width: 14px;
        left: 0;
        top: 0;

      }
    }
    &[readonly],
    &[disabled] {
      & + span.label-text {
        &:before {
          color: rgba(128, 128, 128, 0.5);
        }
        &:after {
          border-color: rgba(187, 187, 187, 0.5);
        }
      }
    }
    &:checked + span.label-text:before {
      opacity: 1;
    }
  }

}
.familyid_auth_key{
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

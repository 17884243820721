
.confirm-user {
  border: 1px solid #D9D9D9;
  background: #FAFAFA;
  border-radius: 4px;
  padding: 9px 10px;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
  .initials {
    width: 56px;
    height: 56px;
    background: #F0F0F0;
    border-radius: 50%;
    margin-right: 8px;
    color: #646464;
    line-height: 1;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    padding-top: 18px;
  }
  .details {
    width: auto;
    height: fit-content;
    .name {
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .email{
      font-size: 14px;
    }
  }
}
.error{
  background-color: #fae3df;
  color: #b61e11;
  border-radius: 4px;
  border: none;
  font-size: 1.3em;
  font-weight: 500;
  text-align: left;
  padding: 1.8rem 1.2rem 1rem 1.2rem;
  line-height: 1.8;
  margin-bottom: 1.25rem;
}

// Login container
#app.auth.login {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, max-content);
}

// Signup container
#app.auth.signup {
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, max-content);
}


.error{
  background-color: #fae3df;
  color: #b61e11;
  border-radius: 4px;
  border: none;
  font-size: 1.3em;
  font-weight: 500;
  text-align: left;
  padding: 1.8rem 1.2rem 1rem 1.2rem;
  line-height: 1.8;
  margin-bottom: 1.25rem;
}


<template>
  <div id="app" class="auth" :class="action">
    <header class="auth-header">
      <div class="row">
        <section class="logo-holder">
          <a :href="$fid.weburl">
            <img :src="arbiterlogo" alt="Arbiter Logo" />
          </a>
        </section>
      </div>
    </header>
    <section role="main">
      <div class="row">
          <div class="form-col large-16">
              <div class="select has-id-parent_locale_select">
                    <span class="selected open">
                      <span class="value">{{ {en: 'English', es: 'Español', so: 'Soomaali', vi: 'Tiếng Việt', it: 'Italiano', pt: 'Português', ru: 'Русский', zh: '中国人', fr: 'Français'}[parent_locale] }}</span>
                      <span class="caret"></span>
                    </span>
                  <select v-model="parent_locale" id="parent_locale_select">
                      <option value="en">English</option>
                      <option value="zh">中国人</option>
                      <option value="it">Italiano</option>
                      <option value="pt">Português</option>
                      <option value="ru">Русский</option>
                      <option value="so">Soomaali</option>
                      <option value="es">Español</option>
                      <option value="vi">Tiếng Việt</option>
                      <option value="fr">Français</option>
                  </select>
              </div>
          </div>
      </div>

      <flash/>
      <router-view ref="view"></router-view>

      <div class="auth-cta" v-if="login_footer()">
        <router-link to="/login" v-if="action=='signup'">{{t('app.shared.if_you_have_account')}}</router-link>
        <router-link to="/signup" v-else>{{t('app.shared.if_you_dont_have_account')}}</router-link>
      </div>

      <div class="auth-cta">
        <div class="row">
          <div class="columns large-24">
            <p style="text-transform: none;">
              {{t('app.shared.contact_us')}}
              <a :href="`mailto:${this.mail_to}`">support@arbitersports.com</a> | 800-311-4060
            </p>
          </div>
        </div>
        <div class="social-media">
          <a href="https://www.facebook.com/ArbiterSports" target="_blank">
            <img :src="iconfb" class="social-icon" alt="Facebook icon"/>
          </a>
          <a href="https://twitter.com/ArbiterSports" target="_blank">
            <img :src="icontw" class="social-icon" alt="Social icon"/>
          </a>
          <a href="https://www.linkedin.com/company/arbiter-sports" target="_blank">
            <img :src="iconli" class="social-icon" alt="Linkedin icon"/>
          </a>
          <a href="https://www.instagram.com/ArbiterSports" target="_blank">
            <img :src="iconin" class="social-icon" alt="instagram icon"/>
          </a>
        </div>
      </div>
    </section>

    <modal v-bind:modal="modal" @close="modal=null"/>
  </div>
</template>

<script>
    import Login from "templates/login.vue"
    import Modal from "templates/modal.vue"
    import Flash from "templates/flash";
    import iconfb from 'images/icon-facebook.png'
    import iconin from 'images/icon-instagram.png'
    import iconli from 'images/icon-linkedin.png'
    import icontw from 'images/icon-twitter.png'
    import arbiterlogo from 'images/arbiter-account-logo.png'
    import I18n from 'code/i18n'
    import axios from 'axios';
    import helpers from 'code/base_mixins'
    import { configure as VeeConfigure } from 'vee-validate';
    import { localize, setLocale } from '@vee-validate/i18n'
    I18n.missingBehaviour="guess"

    export default {
        name: 'app',
        mixins: [helpers],
        components: {
            Flash, Login, Modal
        },
        data: function () {
            return {
                date: null,
                selectedOrg: null,
                modal: null,
                web_msg: null,
                prefill: null,
                action: "",
                hide_menu: false,
                translations: {},
                locale: 'en',
                iconfb, iconin, iconli, icontw, arbiterlogo,
                mail_to: process.env.RAILS_ENV === "production" ? "support@arbitersports.com" : "noreply@arbitersports.com"
            }
        },
        watch: {
            selectedOrg(o) {
                if (this.state.current_user)
                    this.$store.commit('setOrg', this.state.current_user.organizations.find(e => e.id == o))
                else
                    this.$store.commit('setOrg', null)
            },
            '$route' (to, from) {
              this.$route.query.reqid = to.query.reqid
              if (this.$route.query.reqid) {
                //setting redirection token
                axios.interceptors.request.use((config) => {
                  config.params = {...config.params, reqid: this.$route.query.reqid || this.$fid.reqid}
                  this.$fid.reqid = config.params["reqid"]
                  return config
                })
                // getting message by token
                this.$http.post('/api/redirection_token', {
                  reqid: this.$route.query.reqid
                }).then((res) => {
                  this.web_msg = res.data.msg
                  this.prefill = res.data.prefill
                })
              }
              this.calcAction()
            },
            'locale' (to, from){
              I18n.locale = to
              localize(to)
              this.updateTitle()
              this.loadTranslations()
            }
        },
        created() {
            this.calcAction()
            // for translations
            window.I18n = I18n;
            I18n.locale = document.querySelector("html").lang
            this.loadTranslations()
            // end for translations
        },
        methods: {
            loadTranslations(){
              axios.defaults.headers.common['X-Locale'] = I18n.locale

              let key = "fid_i18n_" + I18n.locale
              this.$http.get('/api/config/i18n?locale=' + I18n.locale).then((response)=> {
                this.translations=I18n.translations = response.data
                this.updateTitle()

                window.localStorage.setItem(key, JSON.stringify(response.data))
              })

              if (window.localStorage.getItem(key)){
                this.translations=I18n.translations = JSON.parse(window.localStorage.getItem(key))
              }

              let vue_locale = I18n.locale
              if (vue_locale === "zh") vue_locale = "zh_CN"
              else if (vue_locale === "pt") vue_locale = "pt_PT"

              if (vue_locale === "so") import(`code/so.json`).then(this.applyVeeConfig)
              else import(`@vee-validate/i18n/dist/locale/${vue_locale}.json`).then(this.applyVeeConfig)
            },
            applyVeeConfig(res){
              VeeConfigure({ generateMessage: localize({ [I18n.locale]: res }) })
              setLocale(I18n.locale);
            },
            updateTitle(){
              const title = this.$route.meta.title
              if (title){
                document.title = I18n.t(`app.title.${title}`,
                  {
                    defaultValue: `${title[0].toUpperCase()}${title.substring(1).replace("_"," ")} | Arbiter Registration`
                  }
                )
              }
            },
            calcAction (){
                try{
                    this.action = this.$route.matched[0].components.default.name
                }catch(e){ this.action = "login"}
            },
            login_footer(){return !this.no_login_footer() && !this.hide_menu},
            no_login_footer(){
              try{
                return this.$route.matched[0].props.default.no_login_footer
              }catch(e){
                return false
              }
            },

        },
        computed: {
            state: function () {
                return this.$store.state
            },
        }
    }
</script>

<style scoped>
  section[role="main"]{
    width: 95%;
  }
</style>

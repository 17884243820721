@font-face {
  font-family: 'Proxima Nova';
  src: url('~css/fonts/proximanova.eot');
  src:url('~css/fonts/proximanova.eot?#iefix') format('embedded-opentype'),
  url('~css/fonts/proximanova.woff') format('woff'),
  url('~css/fonts/proximanova.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('~css/fonts/proximanova-bold.eot');
  src: url('~css/fonts/proximanova-bold.eot?#iefix') format('embedded-opentype'),
  url('~css/fonts/proximanova-bold.woff') format('woff'),
  url('~css/fonts/proximanova-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('~css/fonts/proximanova-semibold.eot');
  src: url('~css/fonts/proximanova-semibold.eot?#iefix') format('embedded-opentype'),
  url('~css/fonts/proximanova-semibold.woff') format('woff'),
  url('~css/fonts/proximanova-semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Light';
  src: url('~css/fonts/proximanova-light.eot');
  src: url('~css/fonts/proximanova-light.eot?#iefix') format('embedded-opentype'),
  url('~css/fonts/proximanova-light.woff') format('woff'),
  url('~css/fonts/proximanova-light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@mixin font-regular{
  font-family: 'Proxima Nova';
  font-weight: normal;
  font-style: normal;
}

@mixin font-bold{
  font-family: 'Proxima Nova';
  font-weight: bold;
  font-style: normal;
}

@mixin font-semibold{
  font-family: 'Proxima Nova';
  font-weight: 600;
  font-style: normal;
}

@mixin font-lighter {
  font-family: 'Proxima Nova Light';
  font-weight: normal;
  font-style: normal;
}
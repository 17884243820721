#app.auth.forgot-pass {

  .sent-form {
    text-align: center;
    .buttons {
      margin: 2rem auto 2rem auto;
    }
  }

  .row {

    input.error {
      border: 1px solid $alert-color;
      box-shadow: 0 0 2px rgba(182, 30, 17, 0.49);
    }
  }
}

%alert {
  border-radius: 4px;
  border: none;
  margin-bottom: rem(20px);
  font-size: 1.3rem;
  font-weight: 500;
  text-align: left;
  padding: 1.8rem 1.2rem 1rem 1.2rem;
  line-height: 1.8;
}

.flash-message {
  @extend %alert;
}

.flash-message.notice {
  &:before {
    display: inline-block;
    content: url('~css/icons/notice.svg');
    padding: 0 0.6rem 0 0;
    vertical-align: top;
  }
  background-color: #e5eaf2;
  color: #1b3d73;
  a {
    color: #1b3d73;
  }
}

.flash-message.alert {
  &:before {
    display: inline-block;
    content: url('~css/icons/error.svg');
    padding: 0 0.6rem 0 0;
    vertical-align: top;
  }
  background-color: #fae3df;
  color: #b61e11;
  a {
    color: #b61e11;
  }
}

.flash-message.success {
  &:before {
    display: inline-block;
    content: url('~css/icons/success.svg');
    padding: 0 0.6rem 0 0;
    vertical-align: top;
  }
  background-color: #eaf4e1;
  color: #57a01e;
  a {
    color: #57a01e;
  }
}

.auth-header {
  margin-bottom: 3rem;

  .logo-holder {
    text-align: center;
    img {
      margin: 3rem 3rem 0;
      width: 15rem;
    }
  }
}

#app.auth.signup {

  section[role=main] {
    margin: auto auto 4rem;
    width: 60%;
  }

  @media only screen and (max-width: 1132px) {
    section[role=main] {
      width: 90%;
    }
  }

  @media only screen and (min-width: 768px) {
    #signup-organisation-modal-text {
      width: 100%;
      //display: flex;
      //flex-direction: row;
      //justify-content: center;
    }
    .large-12 {
      position: relative;
      width: 47%;
    }
  }

  .left {
    float: left;
  }

  .row {
    span.error {
      background-color: #fae3df;
      color: $alert-color;
      display: block;
      font-size: 1.3rem;
      margin-top: .5rem;
      text-align: right;
      border-radius: 4px;
      border: none;
      font-weight: 500;
      padding: 1.8rem 1.2rem 1rem 1.2rem;
      line-height: 1.8;
      margin-bottom: 1.25rem;
    }

    label {
      font-size: 1.2rem;
      font-weight: 600;

      small {
        font-weight: 100;
      }
    }

    .select-dropdown {
      input,
      input:focus{
        background-color: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        margin-top: 0;
        padding: 0;
        width: 100%;
      }
    }
  }

  form label {

    &> small {
      margin-left: .5rem;
    }
  }

  form {
    label[for="user_agree_with_terms"] {
      input.hidden-field:not(#user_agree_with_terms) {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 453px) {
    form label > small {
      display: block;
      margin-left: 0;
    }
  }


  label {
    .label-text {
      width: 93%;
      padding-left: 5%;
      &.error {
        color: $alert-color;
      }
    }

    //input:not([id="user_send_marketing_emails"]), input:not([type="user_agree_with_terms"]) {
    //  display: none;
    //}
    //
    //input {
    //  margin-left: 2rem;
    //}
  }

  @media only screen and (max-width: 453px) {
    .field > label {
      max-width: 100%;
    }
  }

  #organization-fields {
    margin-top: 4rem;
    span {
      font-size: 1.4rem;
    }
  }

  .terms-link {
    text-align: left;
  }

  .social-media {
    margin-bottom: 2rem;
  }
}

#app.auth.reset-email {

  .row {

    input.error {
      border: 1px solid $alert-color;
      box-shadow: 0 0 2px rgba(182, 30, 17, 0.49);
    }
  }
}

@mixin button {
  background-color: $color-steelblue;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(71, 109, 167);
  color: $color-white;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 500;
  overflow-wrap: break-word;
  padding: 0.8rem 2.5rem;
  height: 38px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  text-transform: capitalize;
  &:hover,
  &:focus {
    background-color: $color-light-blue;
    box-shadow: 0 2px 8px rgb(135, 168, 217);
  }
  &:active,
  &[disabled],
  &[disabled]:hover{
    background-color: $color-darkblue;
  }
}

@mixin button-white {
  background-color: $color-white;
  box-shadow: 0 2px 8px rgb(175, 172, 176);
  color: $color-darkblue;
  &:hover,
  &:focus {
    background-color: $color-light-blue;
    box-shadow: 0 2px 8px rgb(135, 168, 217);
    color: $color-white;
  }
  &:active {
    background-color: $color-darkblue;
  }
}

@mixin button-green {
  @include button;
  background-color: $color-lime-green;
  box-shadow: 0 2px 8px rgb(87, 160, 30);
  color: $color-white !important;
  &:hover,
  &:focus {
    background-color: $color-light-green;
    box-shadow: 0 2px 8px rgb(110, 185, 45);
  }
  &:active,
  &[disabled],
  &[disabled]:hover{
    background-color: $color-green;
  }
}

@mixin button-red {
  @include button;
  background-color: $color-white !important;
  box-shadow: 0 2px 8px rgb(175, 172, 176) !important;
  color: $alert-color !important;

  &:hover,
  &:focus {
    background-color: $alert-color !important;
    box-shadow: 0 2px 8px rgb(182, 30, 17) !important;
    color: $color-white !important;
  }
  &:active {
    background-color: $color-firebrick;
  }
  &:active,
  &[disabled],
  &[disabled]:hover{
    background-color: $color-firebrick;
  }
}

.button {
  @include button;
}

a.button {
  line-height: 2.4rem;
}

.button.secondary {
  @include button-white;
}

.button.alert {
  @include button-red;
}

.button.success {
  @include button;
}

.button.promotional {
  @include button-green;
}
.buttons.right{float: right}

.buttons.left{float: left}

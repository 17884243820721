@font-face {
  font-family: 'familyid';
  src:url('~css/fonts/familyid-icon/fid-icons.eot?-qf8st1');
  src:url('~css/fonts/familyid-icon/fid-icons.eot?#iefix-qf8st1') format('embedded-opentype'),
  url('~css/fonts/familyid-icon/fid-icons.woff?-qf8st1') format('woff'),
  url('~css/fonts/familyid-icon/fid-icons.woff2?-qf8st1') format('woff2'),
  url('~css/fonts/familyid-icon/fid-icons.ttf?-qf8st1') format('truetype'),
  url('~css/fonts/familyid-icon/fid-icons.svg?-qf8st1#familyid') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin familyid-icon{
  font-family: 'familyid';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


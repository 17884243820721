
    p, ul {
        margin: 0;
        padding: 0;
        list-style: none;
        text-decoration: none;
        border: none;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: transparent;
    }

    ul, ol {
        list-style: none;
    }

    .clear::after {
        content: '';
        display: block;
        height: 0;
        clear: both;
    }


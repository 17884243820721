#app.auth.forgot-email {

  .row {

    span {
      &.error {
        color: $alert-color;
        display: block;
        font-size: 1.4rem;
        margin-top: .5rem;
        text-align: right;
      }
      &.required {
        padding-left: 4px;
      }
    }

    .select-dropdown {
      input,
      input:focus{
        background-color: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        margin-top: 0;
        padding: 0;
        width: 100%;
      }
    }
  }
}


  .permissions {
    border: 1px solid #D9D9D9;
    background: #FAFAFA;
    border-radius: 4px;
    padding: 9px 10px;
    font-size: 12px;
    @media (min-width: 768px) {
      margin-top: 5px;
    }
    ul {
      list-style: disc;
      li {
        margin-left: 10px;
      }
    }
  }
  .content {
    p {
      padding: 0 10px;
    }
  }
  .grid {
    margin: 0 -10px;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-around;
    }
    .grid-2 {
      flex: 0 0 calc(50% - 10px);
    }
  }
  .field {
    label {
      font-size: 12px;
    }
  }
  .custom-select {
    position: relative;
    font-size: 13px;
    margin-bottom: 10px;
    cursor: pointer;
    .selected.placeholder {
      border: 1px solid #D9D9D9;
      padding: 5px 10px;
      border-radius: 4px;
    }
    ul {
      overflow: hidden;
      border: 1px solid #D9D9D9;
      border-radius: 4px;
      background: #fcfcfc;
      position: absolute;
      width: 100%;
      top: calc(100% + 5px);
      display: none;
      li {
        padding: 5px 10px;
        transition: 0.1s background-color;
        cursor: pointer;
        &:hover {
          background-color: white;
        }
      }
    }
    &.open {
      ul {
        display: block;
        z-index: 2;
      }
    }
  }
  .custom-checkbox {
    display: flex;
    align-items: center;
    span{
      padding-left: 5%;
    }
    input[type="checkbox"] {
      border-radius: 2px;
      width: 14px;
      height: 14px;
      appearance: none;
      border: 1px solid #d9d9d9;
      background: #fff;
      position: relative;
      cursor: pointer;
      &:checked {
        &:after {
          color: #476da7;
          border: solid #476da7;
          border-width: 0 2px 2px 0;
          content: "";
          position: absolute;
          top: 0.1rem;
          right: 0.3rem;
          width: 0.5rem;
          height: 0.8rem;
          transform: rotate(45deg);
        }
      }
    }
    label {
      margin-left: 10px;
    }
  }
  .caret {
    position: absolute;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    top: 50%;
    right: 15px;
    margin-top: -3px;
    transform: rotate(45deg) translateY(-50%);
  }
  .note{
    border-radius: 4px;
    border: none;
    font-size: 1.3em;
    font-weight: 500;
    text-align: left;
    padding: 1.8rem 1.2rem 1rem 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.25rem;
  }
  .error{
    background-color: #fae3df;
    color: #b61e11;
    border-radius: 4px;
    border: none;
    font-size: 1.3em;
    font-weight: 500;
    text-align: left;
    padding: 1.8rem 1.2rem 1rem 1.2rem;
    line-height: 1.8;
    margin-bottom: 1.25rem;
  }
